<template>
  <form @submit.prevent="submit">
    <loading-overlay
      :active="Loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="title"
      size="lg"
      color="dark"
      :show.sync="modalActive"
      class="modal-extended"
      :closeOnBackdrop="false"
      @update:show="$emit('close');"
    >
      <CRow>
          <CCol sm="12" lg="12">
            <CCard class="card-simple">
                  <CCardBody>
                      <CRow>
                          <CCol sm="12" lg="5">
                              <CRow>
                                  <CCol sm="12" lg="12" class="text-center">
                                      <picture-input
                                          v-if="modalActive"
                                          ref="imageInput"
                                          width="180"
                                          height="180"
                                          accept="image/svg+xml" 
                                          size="10"
                                          :custom-strings="imgInputTexts"
                                          @change="handleFileUpload"
                                          :imgSrc="imageRoute"
                                      >
                                      </picture-input> 
                                      
                                  </CCol>
                                  <CCol>
                                      <div v-if="$v.image.$error">
                                          <div
                                          class="text-invalid-feedback text-center"
                                          v-if="!$v.image.isValidFile"
                                          >
                                          {{$t('label.badImage')}}
                                          </div>
                                      </div>
                                  </CCol>
                              </CRow>
                          </CCol>
                          <CCol sm="12" lg="7">
                              <CRow>
                                  <CCol sm="12" lg="12">
                                      <CInput
                                          :label="$t('label.name')+' '+'(ES)'"
                                          addLabelClasses="required text-right"
                                          v-uppercase
                                          :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                          :placeholder="$t('label.areaTypeName')"
                                          v-model="$v.TpArea.TpYardAreaNameEs.$model"
                                          :is-valid="hasError($v.TpArea.TpYardAreaNameEs)"
                                          :invalid-feedback="errorMessage($v.TpArea.TpYardAreaNameEs)"
                                      >
                                      </CInput>    
                                  </CCol>
                                  <CCol sm="12" lg="12">
                                      <CInput
                                          :label="$t('label.name')+' '+'(EN)'"
                                          addLabelClasses="required text-right"
                                          v-uppercase
                                          :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                          :placeholder="$t('label.areaTypeName')"
                                          v-model="$v.TpArea.TpYardAreaNameEn.$model"
                                          :is-valid="hasError($v.TpArea.TpYardAreaNameEn)"
                                          :invalid-feedback="errorMessage($v.TpArea.TpYardAreaNameEn)"
                                      >
                                      </CInput>    
                                  </CCol>
                                  <CCol sm="12" lg="12">
                                      <CInput
                                          type="color"
                                          :label="$t('label.color')"
                                          addLabelClasses="required text-right"
                                          :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                          v-model="$v.TpArea.TpYardAreaColor.$model"
                                          :is-valid="hasError($v.TpArea.TpYardAreaColor)"
                                          :invalid-feedback="errorMessage($v.TpArea.TpYardAreaColor)"
                                      />
                                  </CCol>
                                  <CCol sm="12" lg="12" v-if="edit">
                                      <CSelect
                                        :options="statusOptions"
                                        :value.sync="TpArea.Status"
                                        :label="$t('label.status')"
                                        :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                        addLabelClasses="required text-right"
                                        :is-valid="statusSelectColor"
                                      />
                                  </CCol>
                                </CRow>
                          </CCol>
                      </CRow>
                  </CCardBody>
              </CCard>
          </CCol>
      </CRow>
      <template #footer>
          <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="edit ? statusConfirmation(AreaItems.FgActTpYardArea, TpArea.Status, submitFile) : submitFile()"
          >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>
          <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="$emit('close');"
          >
          <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
          </CButton>
      </template>
    </CModalExtended>
  </form>
</template>

<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import { FormTpArea } from '@/_validations/master-yards/MasterOfYards';
import PictureInput from "@/components/picture-input";
import { imgInputTextsHelpers } from '@/_helpers/funciones';

function data() {
return {
  isSubmit: false,
  modalActive: false,
  Loading: false,
  TpArea: {
      TpYardAreaNameEs: '',
      TpYardAreaNameEn: '',
      TpYardAreaColor: '',
      TpYardAreaIconRoute: '',
      TpYardAreaId: '',
      Status: 0
  },
  imageRoute:'',
  image: ''
};
}
function submitFile() {
try {
  this.isSubmit = true;
  this.Loading = true;
  this.$v.$touch();
  if (this.$v.$error) {
    this.isSubmit = false;
    this.Loading = false;
    throw this.$t('label.errorsPleaseCheck');
  }
  if(this.TpArea.TpYardAreaIconRoute == this.image )
    this.submit();
  else{
    this.$http.file('Yard-file', this.image,this.TpArea.TpYardAreaIconRoute)
    .then((response) => {
      this.TpArea.TpYardAreaIconRoute = response.data.data.files[0].path;
      this.submit();
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.isSubmit = false;
      this.Loading = false;
    });
  }
} catch (error) {
  this.$notify({
    group: 'container',
    title: '¡Error!',
    text: error,
    type: "error"
  });
}
}
function submit() {
try {
  this.isSubmit = true;
  this.Loading = true;
  this.$v.$touch();
  if (this.$v.$error) {
  this.isSubmit = false;
  this.Loading = false;
  throw this.$t('label.errorsPleaseCheck');
  }
  let TpYardAreaJson = this.edit ? {
      TpYardAreaId: this.TpArea.TpYardAreaId,
      TpYardAreaNameEs: this.TpArea.TpYardAreaNameEs,
      TpYardAreaNameEn: this.TpArea.TpYardAreaNameEn,
      TpYardAreaColor: this.TpArea.TpYardAreaColor,
      TpYardAreaIconRoute: this.TpArea.TpYardAreaIconRoute,
      Status: this.TpArea.Status
  }:{
      TpYardAreaNameEs: this.TpArea.TpYardAreaNameEs,
      TpYardAreaNameEn: this.TpArea.TpYardAreaNameEn,
      TpYardAreaColor: this.TpArea.TpYardAreaColor,
      TpYardAreaIconRoute: this.TpArea.TpYardAreaIconRoute,
  };

  let metodo = this.edit ? 'PUT':'POST';
  let ruta = this.edit ? 'TpYardArea-update': 'TpYardArea-insert';

  this.$http.ejecutar(metodo, ruta, TpYardAreaJson, { root: 'TpYardAreaJson' })
  .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
      group: 'container',
      title: '¡Exito!',
      text: response.data.data[0].Response,
      type: "success"
      });
      this.resetForm();
  }).catch(err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  }).then(() => {
      this.isSubmit = false;
      this.Loading = false;
  });
} catch (error) {
  this.$notify({
  group: 'container',
  title: '¡Error!',
  text: error,
  type: "error"
  });
}

}
function handleFileUpload() {
  this.image = this.$refs.imageInput.file;
  this.$v.image.$touch();
}
function statusSelectColor() {
  return this.TpArea.Status === 1;
}

function getData(val) {
  console.log(val);
  this.TpArea.TpYardAreaId = val.TpYardAreaId;
  this.TpArea.TpYardAreaNameEs = val.TpYardAreaNameEs;
  this.TpArea.TpYardAreaNameEn = val.TpYardAreaNameEn;
  this.TpArea.TpYardAreaColor = val.TpYardAreaColor;
  let _lang = this.$i18n.locale;
  let imgplaceholder = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
  this.imageRoute = val.TpYardAreaIconRoute?`${this.$store.getters["connection/getBase"]}${val.TpYardAreaIconRoute.replace('public/', '', null, 'i')}`:imgplaceholder;
  this.image = val.TpYardAreaIconRoute ?? '';
  this.TpArea.TpYardAreaIconRoute = val.TpYardAreaIconRoute ?? '';
  this.TpArea.Status = val.FgActTpYardArea ?1:0;
  this.$v.$touch();
}
function resetForm() {
  this.TpArea.TpYardAreaId = '';
  this.TpArea.TpYardAreaNameEs = '';
  this.TpArea.TpYardAreaNameEn = '';
  this.TpArea.TpYardAreaColor = '';
  this.TpArea.TpYardAreaIconRoute = '';
  this.image = '';
  this.imageRoute = '';
  this.TpArea.Status = 0;
  this.$v.$reset();
}
function imgInputTexts() {
return imgInputTextsHelpers(this);
}

export default {
name: 'area-type-modal',
props: {
  modal: Boolean,
  edit: {
  type: Boolean,
  default: false,
  },
  subClassId: {
  type: String,
  default: "",
  },
  AreaItems: {
  type: Object,
  default: "",
  },
  title:{
  type: String,
  default: "",
  }

},
data,
validations(){ return FormTpArea() },
components:{
  PictureInput,
},
mixins: [
  ModalMixin,
],
directives: UpperCase,
methods: {
  statusSelectColor,
  getData,
  submitFile,
  submit,
  handleFileUpload,
  resetForm,
},
computed: {
  imgInputTexts,
},
watch: {
  modal: function (val) {
    this.modalActive = val;
    if(val){
      if (this.edit) {
        this.getData(this.AreaItems);
      }else{
        let _lang = this.$i18n.locale;
        this.imageRoute = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
      }
    }else{
      this.resetForm();
    }
  },
},

};
</script>
<style lang="scss">
.card-simple{
border: 0px;
margin-bottom: 0 !important;
}

.text-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}
</style>